<template>
  <div>
    <Header />
    <hero-section />
    <infos-profit-section />
    <infos-specificity-section />
    <infos-process-section />
    <calculator-section :content="calculator" />
    <simulation-section :content="simulation" />
    <scenario-section :content="scenarios">
      <button-open-dialog />
    </scenario-section>
    <questions-section />
    <agreements-dialog v-if="isVisible" />
    <button-scroll-up />
  </div>
</template>

<script>

import Header from './components/Header'
import InfosProcessSection from '../../components/infos/InfosProcessSection'
import InfosSpecificitySection from '../../components/infos/InfosSpecificitySection'
import InfosProfitSection from '../../components/infos/InfosProfitSection'
import ButtonScrollUp from '../../components/common/ButtonScrollUp'
import AgreementsDialog from '../../components/agreements/AgreementsDialog'
import ScenarioSection from '../../components/scenarios/ScenarioSection'
import SimulationSection from '../../components/simulation/SimulationSection'
import CalculatorSection from '../../components/calculator/CalculatorSection'
import QuestionsSection from '../../components/questions/QuestionsSection'
import ButtonOpenDialog from '../../components/common/ButtonOpenDialog'
import HeroSection from '../../components/hero/HeroSection'

import ContentMixin from '../../mixins/content.mixin'

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    InfosProcessSection,
    InfosSpecificitySection,
    InfosProfitSection,
    ButtonScrollUp,
    AgreementsDialog,
    ScenarioSection,
    SimulationSection,
    CalculatorSection,
    QuestionsSection,
    ButtonOpenDialog,
    HeroSection
  },
  mixins: [ContentMixin]
}
</script>
